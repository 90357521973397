<template>
  <tr class="*:odd:bg-gray-tint *:even:bg-white">
    <td
      class="px-4 py-2 min-w-18 mini:px-6 mini:py-3 sticky left-0 z-[2] bg-inherit"
    >
      <h3 class="my-0 text-sm">
        <NuxtLink :to="product?.urlPath" class="flex gap-3 items-center">
          <div class="w-7 h-7 rounded overflow-hidden">
            <ProductPicture
              sizes="28px"
              :image="{
                src: product?.imageUrl,
                alt: product?.imageAlt,
                srcset: product?.imageSrcset,
              }"
            />
          </div>
          <div class="flex-1">
            {{ product?.name }}
          </div>
        </NuxtLink>
      </h3>
    </td>
    <td
      v-for="prop in properties"
      :key="prop.id"
      class="pr-4 py-2 mini:pr-6 mini:py-4 min-w-18 whitespace-nowrap"
    >
      <div
        v-for="usage in product?.fragrance?.recommendedApplications"
        :key="usage.label"
      >
        <div v-show="prop.label == usage.label">
          <span v-if="usage.value">{{ removeUsage(usage.note) }}</span>
          <span v-else>n/a</span>
        </div>
      </div>
    </td>
  </tr>
</template>

<script setup lang="ts">
  defineProps<{
    product: SolidusProduct
    properties: { label: string; id: string; formattedLabel: string }[]
  }>()

  function removeUsage(str: string) {
    return str.replace("usage: ", "")
  }
</script>
