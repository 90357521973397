<template>
  <Container>
    <div
      class="rounded-xl shadow-lg overflow-scroll overscroll-none my-8 mini:my-12"
    >
      <table class="w-full border-collapse">
        <thead>
          <tr>
            <td
              class="px-4 py-2 mini:px-6 mini:py-4 font-bold bg-white sticky z-[3] left-0 top-0 min-w-18"
            >
              Fragrance
            </td>

            <td
              v-for="property in usageProducts?.header"
              :key="property.id"
              class="pr-4 py-2 mini:pr-6 mini:py-4 font-bold pl-0 min-w-18"
            >
              {{ property.formattedLabel }}
            </td>
          </tr>
        </thead>

        <tbody class="table-striped" v-if="usageProducts">
          <UsageTableProduct
            v-for="product in usageProducts?.products"
            :key="`usageproduct` + product.id"
            :product="product"
            :properties="usageProducts?.header || []"
          />
        </tbody>
      </table>
    </div>
  </Container>
</template>

<script setup lang="ts">
  import { hash } from "ohash"
  import { deserialize } from "@alchemy_cms/json_api"
  import UsageTableProduct from "./usage_table_product.vue"

  const { api } = useApi()

  const props = defineProps<{
    element: AlchemyElement
  }>()

  function removeSafeString(str: string) {
    return str.replace(" Safe", "s").replace(" Approved", "s")
  }

  const { data: usageProducts } = await useAsyncData(
    props.element.id,
    () => {
      const productIds = props.element.nestedElements.flatMap((elements) => {
        return elements.ingredients.flatMap((ingredient) => {
          return ingredient.value
        })
      })

      return api<SolidusProduct[]>("/jsonapi/products/", {
        params: {
          include: "fragrance",
          fields: {
            product: "name,fragrance,urlPath,imageUrl,imageAlt,imageSrcset",
            fragrance: "recommendedApplications",
          },
          filter: {
            id_in: productIds.join(","),
          },
          sort: "name",
        },
      })
    },
    {
      transform: (data) => {
        const deserializedData = deserialize(data) as SolidusProduct[]
        const properties = [
          ...new Set(
            deserializedData.flatMap(
              (product) =>
                product.fragrance?.recommendedApplications.map(
                  (usage) => usage.label,
                ) || [],
            ),
          ),
        ].map((label) => ({
          label,
          id: hash(label),
          formattedLabel: removeSafeString(label),
        }))

        const result = {
          header: properties,
          products: deserializedData,
        }

        return result
      },
    },
  )
</script>
